// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme. Use this as a starting point for your own custom color modes by creating a new theme-specific file like `_variables-dark.scss` and adding the variables you need.

//
// Global colors
//

// scss-docs-start sass-dark-mode-vars
$primary-text-dark:                 $blue-300 !default;
$secondary-text-dark:               $gray-300 !default;
$success-text-dark:                 $green-300 !default;
$info-text-dark:                    $cyan-300 !default;
$warning-text-dark:                 $yellow-300 !default;
$danger-text-dark:                  $red-300 !default;
$light-text-dark:                   $gray-100 !default;
$dark-text-dark:                    $gray-300 !default;

$primary-bg-subtle-dark:            $blue-900 !default;
$secondary-bg-subtle-dark:          $gray-900 !default;
$success-bg-subtle-dark:            $green-900 !default;
$info-bg-subtle-dark:               $cyan-900 !default;
$warning-bg-subtle-dark:            $yellow-900 !default;
$danger-bg-subtle-dark:             $red-900 !default;
$light-bg-subtle-dark:              $gray-800 !default;
$dark-bg-subtle-dark:               mix($gray-800, $black) !default;

$primary-border-subtle-dark:        $blue-700 !default;
$secondary-border-subtle-dark:      $gray-700 !default;
$success-border-subtle-dark:        $green-700 !default;
$info-border-subtle-dark:           $cyan-800 !default;
$warning-border-subtle-dark:        $yellow-800 !default;
$danger-border-subtle-dark:         $red-700 !default;
$light-border-subtle-dark:          $gray-700 !default;
$dark-border-subtle-dark:           $gray-800 !default;

$body-color-dark:                   $gray-500 !default;
$body-bg-dark:                      $gray-900 !default;
$body-emphasis-color-dark:          $gray-100 !default;
$body-secondary-color-dark:         rgba($body-color-dark, .75) !default;
$body-secondary-bg-dark:            $gray-800 !default;
$body-tertiary-color-dark:          rgba($body-color-dark, .5) !default;
$body-tertiary-bg-dark:             mix($gray-800, $gray-900, 50%) !default;
$emphasis-color-dark:               $white !default;
$border-color-dark:                 $gray-700 !default;
$border-color-translucent-dark:     rgba($white, .15) !default;
$headings-color-dark:               #fff !default;
$link-color-dark:                   $blue-300 !default;
$link-hover-color-dark:             $blue-200 !default;
$code-color-dark:                   $pink-300 !default;


//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark !default;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-switch-color-dark:            rgba($white, .25) !default;
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;


//
// Accordion
//

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary-text-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary-text-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end sass-dark-mode-vars
